import React from 'react';
import { Label, Paragraph, Button } from '../components/atomics';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { URL_APP_WEB, URL_EXTENSION_CHROME } from '../constants';



const PlataformaWeb = () => {
  return (
    <Layout>
      <SEO title="Flynow Web" description="Plataforma Web do Aplicativo Flynow" />
      <br/>
      <h1>Flynow Web</h1>
      <Paragraph>Com a Plataforma Web é possível gerenciar todas suas tarefas, hábitos e metas também pelo navegador, além do aplicativo Flynow.</Paragraph>
      <Label>Como acessar a Plataforma Web?</Label>
      <Paragraph>
        A Versão web do Flynow é disponível apenas para usuários premium. Se você ainda não é um usuário premium, você pode se tornar premium através do aplicativo.
        <br/>
        Para acessar a plataforma web, acesse o <a href={URL_APP_WEB} target="_blank" rel="noopener noreferrer">link </a>
        da platorma, faça o login com sua conta do Flynow e utilize à vontade!
      </Paragraph>
      <Paragraph>
        <Button href={URL_APP_WEB} target="_blank" rel="noopener noreferrer" style={{width: '100%', color:'white',alignItems:'center',justifyContent:'center', display:'flex'}}> Acessar Plataforma Web</Button>
        <Button
          href={URL_EXTENSION_CHROME}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            width: "100%",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop:10,
          }}
        >
          {" "}
          Instalar extensão do Chrome
        </Button>
      </Paragraph>
      <br/>
      <iframe
          src="https://www.youtube.com/embed/JBOqIY0iYaI?si=ms-CXl6yYcOfE5RI"
          title="YouTube video player"
          className="video-youtube"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          auto
        ></iframe>
      <br/>
    </Layout>
  )
}

export default PlataformaWeb;